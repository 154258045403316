import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { motion } from "framer-motion/dist/framer-motion";
import { container, title } from "../../utils/motionConfig";
import { testimonialData, textSliderData } from "../../utils";

const options = {
  loop: true,
  margin: 10,
  items: 1,
  autoplay: true,
};

export const TestimonialCarousel = () => {
  return (
    <OwlCarousel className="owl-theme" {...options}>
      {testimonialData.map(({ id, name, desc , image}) => (
        <div className="item" key={id}>
          <div className="testimonial-avater mb-4">
            <img src={image} className="img-fluid" alt="" />
          </div>
          <p className="text-center section-p">{desc}</p>
          <p className="text-white text-center mt-4 text-dark font-weight-bold">
            {name}
          </p>
        </div>
      ))}
    </OwlCarousel>
  );
};

export const SectionTextSlider = () => {
  return (
    <OwlCarousel className="owl-theme" {...options}>
      {textSliderData.map(({ id, titles, desc, image }) => (
        <div className="item" key={id}>
          <div className="row d-flex justify-content-center">
            <motion.div
              className="col-lg-5"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <motion.img
                src={image}
                className="img-fluid slider-image"
                alt="Phones"
                initial={{ y: -200, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              />
            </motion.div>
            <motion.div
              className="col-lg-5 d-flex justify-content-center flex-column"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <motion.h1
                className="text-white text-left mb-4 text-dark"
                variants={title}
              >
                {titles}
              </motion.h1>
              <div className="d-flex justify-content-start flex-column mb-5">
                <motion.p
                  className="text-left section-p"
                  initial={{ y: 200, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5, delay: 1 }}
                >
                  {desc}
                </motion.p>
                <div className="w-100 mt-4">
                  <a
                    href="auth/signup1/1"
                    className="btn-create-account px-3 py-2 fs-4"
                  >
                    Create free account
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
};
