import React from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import "../../assets/css/style.css";
import logo from "../../assets/images/turbomoni-logo2.svg";
import { fadeInUp } from "../../utils/motionConfig";
const LandingPageHeader = () => {
  return (
    <AnimatePresence>
      <motion.header initial="initial" animate="animate" className="header-nav">
        <nav className="navbar navbar-expand-lg navbar-light nav-edit fixed-top">
          <div className="container">
            <NavLink to="/" className="navbar-brand mt-3 header-logo" href="#">
              <motion.img src={logo} alt="" variants={fadeInUp} />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavDropdown"
            >
              <motion.ul className="navbar-nav" variants={fadeInUp}>
                <li className="nav-item nav-menu-padding">
                  <a href="/" className="nav-link menus text-white">
                    Home
                  </a>
                </li>

                <li className="nav-item nav-menu-padding">
                  <NavLink to="/aboutus" className="nav-link menus text-white">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item nav-menu-padding">
                  <a href="/Products" className="nav-link menus text-white">
                    Products
                  </a>
                </li>
                <li className="nav-item nav-menu-padding">
                  <NavLink
                    to="/contactus"
                    className="nav-link menus text-white"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item nav-menu-padding">
                  <NavLink to="/faq" className="nav-link menus text-white">
                    FAQs
                  </NavLink>
                </li>
                <li className="nav-item nav-menu-padding nav-margin-top">
                  <a
                    href="/auth/login"
                    rel="noreferrer"
                    className="nav-link menus text-white"
                  >
                    Sign in
                  </a>
                </li>
                <li className="nav-item nav-menu-padding nav-margin-top">
                  <a
                    href="/auth/signup1/1"
                    rel="noreferrer"
                    className="btn-create-account px-4 py-2 menus nav-link"
                  >
                    Create free account
                  </a>
                </li>
              </motion.ul>
            </div>
          </div>
        </nav>
      </motion.header>
    </AnimatePresence>
  );
};

export default LandingPageHeader;
