export const GeneralQuestions = [
  {
    question: "What is turbomoni?",
    answer1: `turbomoni was created for you, to put you back in control of your finances. turbomoni provides you with special money management tools that help you create mini-digital purses to budget your expenses, make seamless payments, access “buy now & pay later" gadget loan offers and lock up spare cash towards short-term and long-term savings.  `,
    answer2: `Our payments and expense management solutions are designed to be intuitive and user-friendly, making it easy for you to keep track of your finances and make payments on the go.`,
  },

  {
    question: "What are the products available on turbomoni?",
    answer1:
      "There are four main products on turbomoni, carefully curated to help you to achieve maximum financial wellness:",
  },
  {
    question: "How does the buy now pay later loan option work on turbomoni?",
    answer2:
      "To use the buy now pay later loan option on turbomoni, you need an active Stash savings plan with a balance equal to or greater than the loan amount of your desired purchase. If your single Stash plan is not enough, multiple plans can be aggregated to meet this requirement. Simply click the payfi banner on the home tab of the app and provide your registered turbomoni phone number for verification. ",
    answer3:
      "Payfi will then create an account for you and check your eligibility. Once confirmed, you can make payments in instalments according to your preferred schedule. During this time, your Stash account will serve as collateral and be placed on lien, meaning you can't access the funds until the loan is fully settled. Once the loan is paid off, the lien will be released and you can have access to your funds.",
  },

  {
    question: "How do I start saving on turbomoni?",
    answer1:
      "This is very easy, Our sign-up has been oversimplified, fill out a savings behavioural questionnaire that allows us to know you better, select a savings feature that fits your savings objective and get the plan funded. The plan is automatically created for you, you can start saving and enjoying the very best possible interest rates.",
  },

  {
    question:
      "Can I transfer money to third-party beneficiaries through the App?",
    answer1:
      "We have an interesting peer-to-peer transfer feature on turbomoni. This allows you to send money to other members on the platform. We added a little spice that also allows you to request money from other people on the platform as well.",
  },
];

export const SafetySecurity = [
  {
    question: "How safe is my financial information on turbomoni?",
    answer1:
      "Your card details are extremely safe and are stored where they can never be compromised.",
  },

  {
    question: "How safe is my saved money?",
    answer1:
      "We are a fully incorporated Company and a fully registered and licensed Multi-Purpose Cooperative Society. We partner with registered commercial banks and other capital market operators licensed by the CBN and SEC.",
    answer2:
      "All saved funds are warehoused with and managed through our partner bank, so your funds are insured by NDIC.",
  },
  {
    question: "2FA Authentication on turbomoni?",
    answer1:
      "This means there are two layers of security for transactions on your turbomoni account.",
  },

  {
    question: "Where is turbomoni's physical office?",
    answer1:
      "Our physical office is located at No1, Adewale Oshin street, Off Chief Collins, Lekki Phase1, Lagos, Nigeria",

    answer2: "Email: support@turbomoni.ng",
    answer3: "website: https://turbomoni.ng",
  },

  {
    question: "How safe are my card details?",
    answer1:
      "The security of your personal information is our utmost priority, so we work with a PCI-DSS-compliant payment processors, Paystack to handle all card related transactions and your card information is retained with them.. ",

    answer2:
      "Your card details are extremely safe and are stored where they can never be compromised. ",
  },

  {
    question: "How safe is my saved money?",
    answer1:
      "We are a fully incorporated Company and a fully registered and licensed Multi-Purpose Cooperative Society. We partner with registered commercial banks and other capital market operators licensed by the CBN and SEC.  ",

    answer2:
      "All saved funds are warehoused with and managed through our partner Bank, so your funds are insured by NDIC",
  },
];

export const Savings = [
  {
    question: "Do I need to log in every time I want to save?",
    answer1:
      "No. With turbomoni and in partnership with our payment processors, you can automate a recurring debit when setting up your savings plan so that on every due date, the allocated amount is taken from your bank and kept in your savings plan with turbomoni. The process is extremely easy. You only need to log in when you want to create a new savings plan.",
  },

  {
    question: "Can I pause my savings plan anytime?",
    answer1:
      "Once a savings plan has been initiated, you will be unable to pause it but will have to wait for the maturity date to receive your money and interest. In the event of special emergencies, please reach out to our support teams on support@turbomoni.ng",
  },
  {
    question: "Do I get interest on my savings with turbomoni?",
    answer1:
      "Yes, you can choose to earn interest if you want, and you can also view the progress of your savings every day",
  },

  {
    question: "How is my interest paid?",
    answer1:
      "Each time you log into the application, you can view your savings and the accrued interest as at that date. However, the interest amount on your plans will become available to you on the maturity of the plan. In the case of funds in your allocated expense purses, the interest will be credited into the purse at the end of every month.",
  },
  {
    question: "What if I don't want interest on my savings?",
    answer1:
      "You can choose the option of not adding interest to your savings plan.",
  },
];

export const BVN = [
  {
    question: "Why do I have to add my BVN?",
    answer1:
      "We are required by regulations to verify your KYC (know your customer), the BVN is for verification of your identity as it is currently a single source of truth for financial institutions. Also, you will be unable to use the peer to peer transfer feature on the app if you have not provided your BVN.",
  },

  {
    question:
      "What if I no longer have access to the phone number linked to my BVN?",
    answer1:
      "An OTP will be sent to the email linked with your BVN as well if you provided one. However, if there are none, then you will need to proceed to your bank and update your BVN so as to enjoy more features on turbomoni.",
  },
];

export const TargetGroup = [
  {
    question: "What is Target Savings?",
    answer1:
      "Target savings is a plan  set up to achieve a certain goal. You can save for rent, wedding, phones and as many other plans but most importantly, earn money while doing that.",
  },

  {
    question: "What is Group Saving?",
    answer1:
      "Group savings is a target set by a group of users on the app to meet/smash common financial goals. The spice here is the ‘ginger’ you get from being a part of a successful team.",
  },

  {
    question: "How do I activate/create a Target/ Group Savings",
    answer1:
      "Visit our website or download our app to create an account. log in to your dashboard and scroll to the savings plan. select create a savings plan,  select the target and fill the necessary fields.",
    answer2:
      "There is a link to invite friends and family to join a group plan.",
  },

  {
    question: "Can I break my Target or Group Savings at anytime?",
    answer1: "No, you cannot break your target savings until its expiration",
  },
];

export const Interest = [
  {
    question: "Do I get interest on my savings with turbomoni?",
    answer1:
      "Yes you can choose to earn interest if you want, and you can also view the progress of your savings every day.",
  },

  {
    question: "How is my interest paid?",
    answer1:
      "Each time you log into the application, you can view your savings and the accrued interest as at that date. However, the interest amount on your plans will become available to you on the maturity of the plan.",

    answer2:
      "In the case of funds in your allocated expense purses, the interest will be credited into the purse at the end of every month.",
  },

  {
    question: "What if I don't want interest on my savings?",
    answer1:
      "You can choose the option of not adding interest on your savings plan.",
  },
];

export const Withdrawals = [
  {
    question: "When do I withdraw my savings?",
    answer1: "You can withdraw your savings after the target date has arrived.",
    answer2:
      "Funds from matured savings plans and stash will be paid into your default purse, Vibe cash. you can decide to create new purses or plans from there or simply take the money back into your bank account.",
  },

  {
    question: "What are turbomoni's free withdrawal dates?",
    answer1:
      "Free withdrawals are available on the myPurse feature, all day, every day. However, you will be unable to access funds in your savings plans until they mature and in the case of Stash, pay a small breakage fee.",
  },

  {
    question: "How does Referral work?",
    answer1:
      "Users of the app are rewarded for introducing an active customer. A new user must have created a savings plan through any of the features to become an active user.",
  },

  {
    question: "Who can refer people to turbomoni?",
    answer1:
      "Anyone registered on turbomoni has access to a referral code and can refer other people.",
  },

  {
    question: "How can I locate my referral link?",
    answer1:
      "You can locate your referral link by going to “Account” and selecting “Refer and Earn”.",
  },
];

export const FeesCharges = [
  {
    question: "What are the fees and charges I should know of?",
    answer1:
      "There are no fees or charges for using the application. It is free! ",
  },

  {
    question:
      "Are there any charges when I withdraw my savings before the maturity date?",
    answer1: "Savings Plans: you cannot break the plans before they mature",
    answer2:
      "Stash: there is a small breakage fee for breaking before maturity date",
    answer3: "myPurse: free withdrawals all day, every day!",
  },
];

export const Referrals = [
  {
    question: "How does Referral work?",
    answer1:
      "Users of the app are rewarded for introducing an active customer. A new user must have created a savings through any of the features to become an active user. ",
  },

  {
    question: "Who can refer?",
    answer1:
      "Anyone registered on turbomoni has access to a referral code and can refer another person.",
  },

  {
    question: "How can I check my referrals?",
    answer1:
      "You can check your referrals by going to “Account” and  selecting “Refer and Earn” ",
  },
];

export const ourProducts = [
  {
    id: 1,
    title: "myPurse",
    desc: `With our intuitive interface and special features, you'll be able to easily create and categorize
      your expenses, set budgets, and generate reports to get a clear picture of your financial health.
      Plus, you can access your account from anywhere, on any device, so you can stay on top of your
      expenses no matter where you are. `,
  },

  {
    id: 2,
    title: "Target Savings ",
    desc: `Everyone should have the opportunity to save, grow their money and build wealth no matter
     their financial situation, that's what makes turbomoni special; accessibility for everyone.
     With our locked, automated goal-based saving tool, we offer a secure, convenient, and
     rewarding way for you to save for your future. Whether you're saving for a down payment on a
     house, a vacation, or just for a rainy day, turbomoni is here to help you reach your financial
     goals while also earning impressive interest on your savings. `,
  },
  {
    id: 3,
    title: "Bills Payment ",
    desc: `Enjoy massive cost savings through our special discounts when you pay your bills with
     turbomoni, whether it is utility bills, mobile airtime bills or data bills.
     Experience the convenience of scheduling your bills payment with turbomoni. Now you dont
     need to worry about forgetting payment due dates or merchant account numbers.`,
  },
  {
    id: 4,
    title: "Buy Now Pay Later",
    desc: `We recognise that sometimes life throws unexpected expenses our way and it can be difficult
     to come up with the funds right away. turbomoni in partnership with Payfi gives you the ability
     to purchase what you need now and pay for it later.
     We want to help you get the things you need when you need them. Whether it's a new
     appliance, furniture for your home, or a much-needed vacation, our Buy Now Pay Later option
     is here to help. `,
  },
];
