import IconBox1 from "../assets/images/Icon-Box-1.svg";
import IconBox2 from "../assets/images/Icon-Box-2.svg";
import IconBox3 from "../assets/images/Icon-Box-3.svg";
import IconBox4 from "../assets/images/Icon-Box-4.svg";
import ImageSlider1 from "../assets/images/slide-1.svg";
import ImageSlider2 from "../assets/images/slide-2.svg";
import ImageSlider3 from "../assets/images/slide-3.svg";
import ImageSlider4 from "../assets/images/slide-4.svg";
import ImageSlider5 from "../assets/images/slide-5.svg";
import Avatar1 from "../assets/images/ladie2.svg";
import Avatar2 from "../assets/images/ladie.svg";
import Avatar3 from "../assets/images/ladie2.svg";
import Avatar4 from "../assets/images/guy2.svg";

export const pageData = [
  {
    columnOne: [
      {
        id: 1,
        desc: `Easily track and categorize your expenses, create budget plans, and make payments with a few clicks.`,
        icon: IconBox1,
      },
      {
        id: 2,
        desc: `Our user-friendly platform allows you to see where your money is going and make informed financial decisions.`,
        icon: IconBox2,
      },
      {
        id: 3,
        desc: `Welcome to a better way to do personal finance
        One platform that truly cares about your money!`,
        icon: IconBox3,
      },
      {
        id: 4,
        desc: `You aren't bad with money... It was just never this easy to be good.
        This is the smartest financial decision you’ll ever make
        `,
        icon: IconBox4,
      },
    ],
  },
];

export const serviceData = [
  {
    rowOne: [
      {
        id: 1,
        title: "Secure Payment Gateway",
        desc: `We use a secure payment gateway that is PCI 
        DSS compliant to ensure that your credit card and banking
         information is stored and transmitted in a secure manner.`,
      },
      {
        id: 2,
        title: "Two-Factor Authentication",
        desc: `We offer two-factor authentication to add an extra layer of 
                security to your account login process. This requires you to enter a 
                code that is sent to your phone or email in addition to your password.
                `,
      },
      {
        id: 3,
        title: "Data Encryption",
        desc: `Your personal and financial information is encrypted and stored 
                securely in our database to protect it from any potential breaches.`,
      },
    ],

    rowTwo: [
      {
        id: 1,
        title: "Fraud Detection",
        desc: `Our system is equipped with advanced fraud detection technology 
                to catch any suspicious activity and protect your accounts from unauthorized access.
                `,
      },
      {
        id: 2,
        title: "NDIC Insured",
        desc: `Your saved funds are NDIC insured, meaning that they 
                are backed by the full faith and credit of the Nigerian government.
                `,
      },
      {
        id: 3,
        title: "Account Monitoring",
        desc: `Our team constantly monitors your account for any 
                unusual activity to ensure that your saved funds are safe and secure.
                `,
      },
    ],
  },
];

export const textSliderData = [
  {
    id: 1,
    titles: "Send and Receive Money",
    image: ImageSlider1,
    desc: `We believe transferring and receiving money should be quick and easy for everyone. Send and receive money instantly, without the need for bank transfers. Whether you are paying a friend back for dinner, sending money to a family member, making payments to merchants or receiving money from your customers, turbomoni makes it all possible while also feeling confident that you are getting the best service
  Create a free account now to start sending money!`,
  },
  {
    id: 2,
    titles: "Wealth Building for Everyone",
    image: ImageSlider2,
    desc: `Everyone should have the opportunity to save, grow their money and build wealth no matter their financial situation, that's what makes turbomoni special; accessibility for everyone. 
    With our locked, automated goal-based saving tool, we offer a secure, convenient, and rewarding way for you to save for your future. Whether you're saving for a down payment on a house, a vacation, or just for a rainy day, turbomoni is here to help you reach your financial goals while also earning impressive interest on your savings.
    Create a free account now to start saving towards your future.`,
  },
  {
    id: 3,
    titles: "myPurse",
    image: ImageSlider3,
    desc: `With our intuitive interface and special features, you'll be able to easily create and categorize your expenses, set budgets, and generate reports to get a clear picture of your financial health. 
    Plus, you can access your account from anywhere, on any device, so you can stay on top of your expenses no matter where you are.
    Create a free account to start managing your expenses easily.`,
  },
  {
    id: 4,
    titles: "Buy Now Pay Later",
    image: ImageSlider4,
    desc: `Shop and pay over time with ease
    We recognise that sometimes life throws unexpected expenses our way and it can be difficult to come up with the funds right away. turbomoni in partnership with Payfi gives you the ability to purchase what you need now and pay for it later. 
    We want to help you get the things you need when you need them. Whether it's a new appliance, furniture for your home, or a much-needed vacation, our Buy Now Pay Later option is here to help.
    Create a free account now to start buying and paying small small.`,
  },
  {
    id: 5,
    titles: "Why pay more when you can pay less?",
    image: ImageSlider5,
    desc: `Save money every time you pay bills
    Enjoy massive cost savings through our special discounts when you pay your bills with turbomoni, whether it is utility bills, mobile airtime bills or data bills. 
    Experience the convenience of scheduling your bills with turbomoni. Now you don't need to worry about forgetting payment due dates.
    Create a free account now to start scheduling and paying your bills.`
  },
];

export const aboutData = [
  {
    rowOne: [
      {
        id: 1,
        title: "Our Culture",
        desc: `At turbomoni, our culture is centred around a strong 
        sense of community and teamwork. We believe that we can achieve 
        great things by working together and supporting each other.`,
      },
      {
        id: 2,
        title: "Innovative",
        desc: `Our company culture is one that fosters creativity and encourages employees to come 
        up with new and innovative ideas. We believe that by encouraging our team to think 
        outside the box, we can stay ahead of the competition and continually improve our products and services.
                `,
      },
      {
        id: 3,
        title: "Collaborative",
        desc: `At our company, we value teamwork and collaboration. We believe that working 
        together can achieve more than we could on our own. Our employees are encouraged
         to share ideas and work together to find solutions to challenges.`,
      },
    ],

    rowTwo: [
      {
        id: 1,
        title: "Customer-centric ",
        desc: `Our company culture is centred around providing the best possible experience 
        for our customers. We strive to understand their needs and exceed their expectations,
         and our employees are encouraged to put the customer first in everything they do.`,
      },
      {
        id: 2,
        title: "Diverse and inclusive",
        desc: `We believe that a diverse and inclusive workplace helps us to better understand and
         serve our customers, and are committed to building a team that reflects the communities
          we serve. We value diversity in all its forms and strive to create an environment where 
          everyone feels welcomed and respected. `,
      },
      {
        id: 3,
        title: "Growth-Oriented",
        desc: `Our company values personal and professional development, and we believe that investing
         in our employees is key to our success. We offer training and development opportunities 
         and encourage employees to take on new challenges and responsibilities.`,
      },
    ],
  },
];


export const testimonialData = [
  {
    id: 1,
    name: 'Linda Adebayo Dafe',
    desc: `I started investing with Investield a couple of months now and in all honesty, I will say I have had a great experience navigating through the online platform thus far. I'm also thrilled about how user-friendly the platform is and how it has enabled me to save my funds seamlessly with high amazing returns on investment.
        I'd say for sure it's my go-to plug 100% when it comes to saving my funds or investing. It has really helped my saving culture. All thanks to Investyield😊. Let’s explore this platform together guys🤩🤩`,
    image: Avatar1,
  },
  {
    id: 2,
    name: 'Miriam Emdin',
    desc: `My set-up journey was seamless with proper clarification provided. I was able to make my contributions in a matter of minutes.`,
    image: Avatar2,
  },
  {
    id: 3,
    name: 'Vivianne O.',
    desc: `Hi, I’m Vivianne, a Fashion Designer. I plan to expand my business and move it to the next level. After doing my research and setting a budget to scale my business, I wanted a platform where I can save toward this goal. A friend introduced me to investyield (now turbomoni) and since then everything has changed. turbomoni is helping me save toward expanding my business while also earning good interest on my saved funds, and yes, my funds are absolutely safe on turbomoni.
        You too can save toward your business plan by joining turbomoni, and guess what, registration is absolutely free.`,
    image: Avatar3,
  },
  {
    id: 4,
    name: 'Abiola',
    desc: `I get to enjoy seamless transactions with turbomoni (formerly investYield) without any delay in payment. Whenever I withdraw my money from my wallet, my bank account gets credited asap. Wow, this is amazing!
        `,
    image: Avatar4,
  },
];
