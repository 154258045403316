import React from "react";

const Index = () => {
  return (
    <>
      <main>
        <section className="">
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12">
                <div className="">
                  <h3 className="text-blue">Privacy Policy</h3>
                  <p className="text-black mt-4">
                  This privacy policy (this "Privacy Policy") explains 
                  how personal information is collected, used, stored, 
                  and disclosed by Revenuebull Technologies Ltd, ("turbomoni," 
                  "we," "us," and "our"). The provisions in this Privacy Policy 
                  supersede all previous notices and statements regarding our 
                  privacy practices concerning our services.
                  </p>
                  <p className="text-blue mt-4">
                    Last updated: April 10th, 2023
                  </p>
                </div>
                <div className="mt-4">
                  <h5 className="text-blue">
                    Application of this Privacy Policy
                  </h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                    This Privacy Policy applies generally to the business of 
                    turbomoni and serves to set out our approach to personal 
                    data, inclusive of the personal data of our consumer users 
                    ("Users"). This Privacy Policy applies to systems, 
                    operations and processes of turbomoni that involve 
                    the collection, use, storage, and disclosure etc. of 
                    personal information.
                    </li>
                    <li className="font-sm">
                    This Privacy Policy applies to the use of our websites,
                    applications, other online services and/or any related 
                    services, sales, marketing, promotional or events, and 
                    social media activities (collectively, our "Services"). 
                    This Privacy Policy is part of our Terms of Use for our Users.
                    </li>
                    <li className="font-sm">
                    This Privacy Policy applies to your use of (regardless of 
                    means of access) our Services. You may access or use our 
                    Services through a desktop, laptop, mobile phone, tablet, 
                    or other consumer electronic device (each, a “Device”). 
                    This Privacy Policy also applies to offline interactions with turbomoni.
                    By accessing or using our Services, you agree to this Privacy Policy.
                    </li>
                    <li className="font-sm">
                      In general, we collect Personal Information you give us by
                      filling in forms or by corresponding with us by phone,
                      email, social media posts & messages, or otherwise.
                    </li>
                    <li className="font-sm">
                      <strong> Information About Your Transactions</strong>
                      <br />
                      We collect Personal Information about your transactions
                      with us and others.
                      <br />
                      <br />
                      <strong>Information From Third Parties</strong>
                      <br />
                      We also collect Personal Information about you from other
                      companies. For instance, we may receive Personal
                      Information about you from a consumer reporting agency or
                      we may collect Personal Information from your employer
                      where your employer is providing a service to turbomoni.
                      We may collect your Personal Information from a User where
                      your Personal Information is filled in a Next-of-Kin form.
                      <br />
                      <br />
                      <strong>Information Automatically Collected</strong>
                      <br />
                      We (or our service providers acting on our behalf) may
                      collect information about your use of our Services. This
                      information may include Personal Information as well as
                      statistical information that does not identify you
                      ("Analytics"). Some Analytics may be correlated with
                      Personal Information. When Analytics are, directly or
                      indirectly, associated or combined with Personal
                      Information, such Analytics will be considered Personal
                      Information for purposes of this Privacy Policy.
                      Information that we automatically collect in connection
                      with your access or use of our Services may include:
                      Device Information: We may collect Device-specific
                      information (such as hardware model, operating system
                      version, unique Device identifiers, and mobile network
                      Information, including your mobile phone number). We may
                      how you use our Services. Additional general information
                      about cookies and how they work is available at
                      www.allaboutcookies.org. The cookies we use in connection
                      with our Services include: <br />
                      <br />
                      <strong>Session cookies:</strong> Session cookies are
                      temporary cookies that expire and are automatically erased
                      whenever you close your browser window. We use session
                      cookies to grant users access to content and to enable
                      actions they must be logged into their turbomoni account
                      to perform.
                      <br />
                      <br />
                      <strong>Persistent cookies:</strong> Persistent cookies
                      usually have an expiration date in the distant future and
                      remain in your browser until they expire or you manually
                      delete them. We use persistent cookies to better
                      understand usage patterns so we can improve our Services.
                      For example, we may use a persistent cookie to associate
                      you with your turbomoni account or to remember your
                      choices for our Services.
                      <br />
                      <br />
                      <strong>Third-party cookies:</strong> We permit certain
                      third parties to place cookies through our Services to
                      provide us with better insights into the use of our
                      Services and user demographics and to advertise our
                      Services to you. These third parties may collect
                      information about your online activities over time and
                      across different websites when you access or use our
                      Services. For example, we utilize Google Analytics to
                      analyze usage patterns for our Services. Google Analytics
                      generates a cookie to capture information about your use
                      of our Services, which Google uses to compile reports on
                      website activity for us and to provide other related
                      services. Google may use a portion of your IP address to
                      identify its cookie, but this will not be associated with
                      any other data held by Google. We may also permit
                      third-party service providers to place cookies for our
                      Services, as indicated above, to perform analytic or
                      marketing functions where you are notified of them and you
                      have consented to the usage. We do not control the use of
                      such third-party cookies or the resulting information, and
                      we are not responsible for any actions or policies of such
                      third parties. By about your turbomoni account or our
                      ongoing business relations, unless you withdraw your
                      consent to receive electronic communications as provided
                      in our Terms of Use.
                      <h6>
                        <strong> How We Share Information</strong>
                      </h6>
                      We do not share your Personal Information with:
                      <br />
                      <br />
                      1. Other financial companies for joint marketing purposes;
                      <br />
                      2. Affiliated companies for their everyday business
                      purposes; or <br />
                      3. Any third parties so they can market to you.
                      <br />
                      <br />
                      We may share your Personal Information with unaffiliated
                      third parties:
                      <br />
                      <br />
                      1. If you request or authorize it;
                      <br />
                      2. If the information is provided to help complete a
                      transaction for you;
                      <br />
                      3. If the information is provided to: (a) comply with
                      applicable laws, rules, regulations, governmental and
                      quasi-governmental requests, court orders, or subpoenas;
                      (b) enforce our Terms of Use or other agreements; or (c)
                      protect our rights, property, or safety or the rights,
                      property, or safety of our users or others (e.g., to a
                      consumer reporting agency for fraud protection, etc.);
                      <br />
                      4. If the disclosure is done as part of a purchase,
                      transfer, or sale of services or assets (e.g., in the
                      event that substantially all of our assets are acquired by
                      another party, your Personal Information may be one of the
                      transferred assets);
                      <br />
                      5. If the information is provided to our third-party
                      service providers to perform functions on our behalf
                      (e.g., analysing data, providing marketing assistance,
                      providing customer service, processing orders, etc.);
                      <br />
                      6. For our everyday business purposes;
                      <br />
                      7. For publication of our events and competitions on
                      digital and print media.
                      <br />
                      We share your Personal Information with delivery companies
                      and may publish your image where you have won a gift in
                      our competitions,
                      <li>
                        <strong>
                          Request Access to your Personal Information
                        </strong>
                      </li>
                      You can ask us whether we are storing your Personal
                      Information and you can ask to receive a copy of that
                      Personal Information. Before sending you any Personal
                      Information, we will ask you to provide proof of your
                      identity. If you are not able to provide proof of your
                      identity, we reserve the right to refuse to send you any
                      Personal Information. We will respond as quickly as we can
                      to your requests for details of Personal Information, we
                      hold about you.
                      <li>
                        <strong>
                          Request us to refrain from doing certain things with
                          your data or restrict the extent of our collection or
                          processing of your data
                        </strong>
                      </li>
                      You may object to the processing of your personal data
                      where you feel it impacts your fundamental rights and
                      freedoms. You also have the right to object where we are
                      processing your personal data for direct marketing
                      purposes. In some cases, we may demonstrate that we have
                      compelling legitimate grounds to process your information
                      which override your rights and freedoms.
                      <li>
                        <strong>
                          Request partial or complete erasure of your Personal
                          Information
                        </strong>
                      </li>
                      You may request for us to delete or remove personal data
                      where there is no good reason for us continuing to process
                      it. You also have the right to ask us to delete or remove
                      your personal data where you have successfully exercised
                      your right to object to processing, where we may have
                      processed your information unlawfully or where we are
                      required to erase your personal data to comply with local
                      law. Note however that we may not always be able to comply
                      with your request of erasure for specific legal reasons
                      which will be notified to you, if applicable, at the time
                      of your request.
                      <li>
                        <strong>
                          Request the transfer of your Personal Information to
                          another party
                        </strong>
                      </li>
                      <li>
                        <strong>
                          Object to decisions being taken by automated means
                          which produce legal effects concerning you or
                          similarly significantly affect you
                        </strong>
                      </li>
                      <br />
                      <li>
                        <strong>Information You Share Socially</strong>
                      </li>
                      No method of electronic transmission or storage is 100%
                      secure. Therefore, we cannot guarantee the absolute
                      security of your Personal Information. You also play a
                      role in protecting your Personal Information. Please
                      safeguard your username and password for your turbomoni
                      account and do not share them with others. If we receive
                      instructions using your turbomoni account login
                      information, we will consider that you have authorized the
                      instructions.
                      <br />
                      <br />
                      You agree to notify us immediately of any unauthorized use
                      of your turbomoni account or any other breach of security.
                      We reserve the right, in our sole discretion, to refuse to
                      provide our Services, terminate turbomoni accounts, and
                      remove or edit content. Subject to applicable law, which
                      might, from time to time, oblige us to store your Personal
                      Information for a certain period of time, we will retain
                      your Personal Information for as long as necessary to
                      fulfil the purposes we collected it for, including the
                      purposes of satisfying any legal, accounting, or reporting
                      requirements.
                      <li>
                        <strong> Protecting Children's Privacy</strong>
                      </li>
                      Our Services are not directed or intended to be
                      attractive, to children under the age of 18. We do not
                      knowingly collect Personal Information from children under
                      the age of 18. If you are under the age of 18, do not use
                      our Services or submit any information to us.
                      <li>
                        <strong>Links to Third-Party Websites</strong>
                      </li>
                      When you use our Services, you may be directed to other
                      websites that are beyond our control. We may also allow
                      third-party websites or applications to link to our
                      Services. We are not responsible for the privacy practices
                      of any third parties or the content of linked websites,
                      but we do encourage you to read the applicable privacy
                      policies and terms and conditions of such parties and
                      websites. This Privacy Policy only applies to our
                      Services.
                      <li>
                        <strong> Changes to our Privacy Policy</strong>
                      </li>
                      <br />
                      By accessing or using our Services, you agree to this
                      Privacy Policy.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">Information We Collect</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      <li>
                        <strong>Information You Provide Us</strong>
                      </li>
                      In general, you can visit https://www.turbomoni.ng without
                      telling us whom you are or revealing any information about
                      yourself. <br /> <br />
                      When you submit an inquiry to us via any means, register
                      for a turbomoni account, register to attend a turbomoni
                      event, interact with us on social media, partake in
                      competitions and promotional activities such as our
                      "giveaway" and volunteer information to us; we may collect
                      personal information from you, which may include your
                      name, email address, mobile phone number, banking
                      information, image and other information that identifies
                      you (collectively, "Personal Information"). By providing
                      your Personal Information to us, you expressly agree to
                      our collection, use, storage, and disclosure of such
                      information as described in this Privacy Policy.
                      <br />
                      <br />
                      As a User, we will also ask you to create login
                      information for your turbomoni account, such as a username
                      and password. When you provide your mobile phone number,
                      we may ask for your consent to receive text messages
                      relating to our Services on that number. associate your
                      Device identifiers or mobile phone number with your
                      turbomoni account.
                      <br />
                      <br />
                      Log Information: We may record or log information from
                      your Devices, their software, and your activity accessing
                      or using our Services. This information may include:
                      <br />
                      1. The Device's Internet Protocol ("IP") address <br />
                      2. Identification numbers associated with your Devices{" "}
                      <br />
                      3. Device event information, such as crashes, system
                      activity, and hardware settings <br />
                      4. Location preferences
                      <br />
                      5. Date and time stamps of transactions <br />
                      6. System configuration information <br />
                      7. Other interactions with our Services
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">
                    Information Collected Through Cookies and Similar
                    Technologies
                  </h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      We use cookies to personalize our Services for you and to
                      collect aggregate information about the usage of our
                      Services. A cookie is a text file or other local storage
                      identifier provided by your browser or associated
                      applications. We use cookies for record-keeping purposes
                      and to enhance the quality of your use of our Services.
                      The cookies assign random, unique numbers to your Devices
                      to enable our systems to recognize your Devices and to
                      allow us to see accessing or using our Services, you
                      consent to the placement of cookies on your Devices as
                      described in this Privacy Policy.
                      <br /> <br />
                      If you prefer not to receive cookies through our Services,
                      you may control how your browser responds to cookies by
                      adjusting the privacy and security settings of your web
                      browser. Unless you set your browser settings to refuse
                      all cookies, our system may issue cookies when you access
                      or use our Services. If you set your browser settings to
                      refuse all cookies, the performance of certain features of
                      our Services may be limited or not work at all.
                 
                      <br />
                      <li>
                        <strong>Do-Not-Track Signals</strong>
                      </li>
                      <br />
                      Do Not Track ("DNT") is an optional browser setting that
                      allows you to express your preferences regarding tracking
                      by advertisers and other third parties. We do not use
                      technology that recognizes DNT signals from your web
                      browser.
                      <br />
                      <br />
                      <li>
                        <strong>How We Use Information</strong>
                      </li>
                      <br />
                      We may use Analytics as described elsewhere in this
                      Privacy Policy and for research and commercial purposes,
                      such as to improve our Services. We may use Personal
                      Information for the purposes described elsewhere in this
                      Privacy Policy and internally for our general commercial
                      purposes, including, among other things, to offer our
                      products and services and products and services of third
                      parties that we think you might find of interest. Only
                      turbomoni and our third-party service providers involved
                      in distributing the offers or providing the products or
                      services will have access to your Personal Information.
                      Our third-party service providers will only be permitted
                      to use Personal Information for that intended purpose. We
                      may use your email address to respond to your inquiries
                      and to provide information about our Services. You may
                      elect not to receive promotional emails from us either by
                      "unsubscribing" to an email you receive from us or by
                      contacting us as indicated below. As a User, if you
                      unsubscribe from receiving emails from us, we may still
                      send you non-promotional emails, such as emails giveaways
                      and promotions or (8) as permitted by applicable law or
                      otherwise described in this Privacy Policy. When you are
                      no longer our User, we may continue to share your
                      information as described in this Privacy Policy. We may
                      disclose Analytics with third parties as described
                      elsewhere in this Privacy Policy and for our commercial
                      purposes.
                      <br />
                      <br />
                      <li>
                        <strong>Your Rights</strong>
                      </li>
                      <br />
                      If you do not wish to receive offers or other notices from 
                      us in the future, you can "opt-out" by contacting us as 
                      indicated at the end of this Privacy Policy or by following 
                      the "unsubscribe" instructions in any communication you 
                      receive from us. Please be aware that if you are a User, you 
                      are not able to opt out of receiving communications about your 
                      turbomoni account or related transactions with us.
                      <br />
                      <li>
                        <strong>
                          Other Rights to Your Personal Information with Us
                        </strong>
                      </li>
                      Beyond your right to opt out of our processing of your Personal 
                      Information, you also have the option of exercising any of the 
                      below rights with respect to your Personal Information: 
                      <li>
                      <strong>Request Account Deletion</strong>
                      </li>
                      <br />
                      We respect your wishes to request that we delete your account 
                      with us should you not want to continue using our products and services.
                      <br />
                      <li>
                      <strong>Request Correction to your Personal</strong>
                      </li>
                      <br />
                      Information We respect your wishes to correct inaccurate
                      information and as such you must notify us of any change
                      in your Personal Information. If you are a User, you can
                      change some of your Personal Information by updating your
                      turbomoni account profile through our Services. Changes to
                      financial details will require you to contact us directly.
                      If you are not a User, you can contact us as indicated at
                      the end of this Privacy Policy to make any changes or
                      requests on Personal Information we hold about you. Any
                      changes will affect only future uses of your Personal
                      Information and we may need to verify the accuracy of the
                      new data you provide to us.
                      <br />
                      <li>
                      <strong>Request Access to your Personal Information</strong>
                      </li>
                      <br />
                      You can ask us whether we are storing your Personal Information 
                      and you can ask to receive a copy of that Personal Information. 
                      Before sending you any Personal Information, we will ask you to 
                      provide proof of your identity. If you are not able to provide 
                      proof of your identity, we reserve the right to refuse to send 
                      you any Personal Information. We will respond as quickly as we 
                      can to your requests for details of the Personal Information, 
                      we hold about you.
                      <br />
                      <li>
                      <strong>Request us to refrain from doing certain things with 
                      your data or restrict the extent of our collection or 
                      processing of your data</strong>
                      </li>
                      <br />
                      You may object to the processing of your personal data where 
                      you feel it impacts your fundamental rights and freedoms. 
                      You also have the right to object where we are processing 
                      your personal data for direct marketing purposes. 
                      In some cases, we may demonstrate that we have compelling 
                      legitimate grounds to process your information which 
                      override your rights and freedoms.
                      <br />
                      <li>
                      <strong>Request partial or complete erasure of your Personal 
                      Information</strong>
                      </li>
                      <br />
                      You may request for us to delete or remove personal data 
                      where there is no good reason for us continuing to process 
                      it. You also have the right to ask us to delete or remove 
                      your personal data where you have successfully exercised 
                      your right to object to processing, where we may have 
                      processed your information unlawfully or where we are 
                      required to erase your personal data to comply with local 
                      law. Note however that we may not always be able to comply 
                      with your request of erasure for specific legal reasons which 
                      will be notified to you, if applicable, at the time of your request.
                      <br />
                      <li>
                      <strong>Request the transfer of your Personal Information to another 
                        party</strong>
                      </li>
                      <br />
                      <li>
                      <strong>Object to decisions being taken by automated means which 
                      produce legal effects concerning you or similarly significantly 
                      affect you.</strong>
                      </li>
                      <br />
                      <br />
                      Our Services may allow you to connect and share your
                      actions, comments, content, and information publicly or
                      with friends. We are not responsible for maintaining the
                      confidentiality of any information you share publicly or
                      with friends.
                      <br />
                      <br />
                      <li>
                      <strong>Information You Share Socially</strong>
                      </li>
                      Our Services may also allow you to connect with us on,
                      share on, and use third-party websites, applications, and
                      services. Please be mindful of your personal privacy needs
                      and the privacy needs of others, as you choose whom to
                      connect with and what to share and make public. We cannot
                      control the privacy or security of information you choose
                      to make public or share with others. We also do not
                      control the privacy practices of third parties. Please
                      contact those sites and services directly if you want to
                      learn about their privacy practices.
                      <br />
                      <li>
                        <strong>
                          Security and Storage of your Personal Information
                        </strong>
                      </li>
                      We have suitable security measures in place to prevent
                      your personal information from being accidentally lost or
                      used or accessed in an unauthorised way by a third party.
                      When your bank account information is transmitted via our
                      Services, it will be protected by encryption technology,
                      such as Secure Sockets Layer (SSL). In addition, we limit
                      access to your personal data to those employees, agents,
                      contractors and other third parties who have a business
                      need to know. They will only process your Personal
                      Information on our instructions. We have put in place
                      procedures to deal with any suspected personal data breach
                      and will notify you and any applicable regulator of a
                      breach where we are legally required to do so. We have,
                      and require our third-party service providers that receive
                      Personal Information from us to have, a comprehensive
                      written information security program that contains
                      administrative, technical, and physical safeguards for our
                      respective physical facilities and in our respective
                      computer systems, databases, and communications networks
                      that are reasonably designed to protect information
                      contained within such systems from loss, misuse, or
                      alteration.
                      <br />
                      <br />
                      No method of electronic transmission or storage is 100% secure. 
                      Therefore, we cannot guarantee the absolute security of your 
                      Personal Information. You also play a role in protecting your 
                      Personal Information. Please safeguard your username and 
                      password for your turbomoni account and do not share them with 
                      others. If we receive instructions using your turbomoni account 
                      login information, we will consider that you have authorized the 
                      instructions. You agree to notify us immediately of any 
                      unauthorized use of your turbomoni account or any other breach of 
                      security. In our sole discretion, we reserve the right to refuse 
                      to provide our Services, terminate turbomoni accounts, and remove 
                      or edit content.
                      <br />
                      <br />
                      Subject to applicable law, which might, from time to time, oblige 
                      us to store your Personal Information for a certain period of time, 
                      we will retain your Personal Information for as long as necessary 
                      to fulfil the purposes we collected it for, including the purposes 
                      of satisfying any legal, accounting, or reporting requirements.
                      <br />
                      <br />
                      <li>
                      <strong>Protecting Children's Privacy</strong>
                      </li>
                      Our Services are not directed or intended to be attractive, to 
                      children under the age of 18. We do not knowingly collect 
                      Personal Information from children under the age of 18. If you 
                      are under the age of 18, do not use our Services or submit any 
                      information to us.
                      <br />
                      <li>
                      <strong>Links to Third-Party Websites</strong>
                      </li>
                      When you use our Services, you may be directed to other websites 
                      that are beyond our control. We may also allow third-party websites 
                      or applications to link to our Services. We are not responsible for 
                      the privacy practices of any third parties or the content of linked 
                      websites, but we do encourage you to read the applicable privacy 
                      policies and terms and conditions of such parties and websites. 
                      This Privacy Policy only applies to our Services.
                      <br />
                      <li>
                        <strong>
                        Changes to our Privacy Policy
                        </strong>
                      </li>
                      Subject to applicable law, we may revise this Privacy
                      Policy at any time and at our sole discretion. When we
                      revise this Privacy Policy, we will post the revised
                      version via our Services and will update the date at the
                      top of this Privacy Policy. The revised Privacy Policy
                      will be effective upon posting via our Services, unless
                      otherwise set forth therein or as otherwise required by
                      applicable law. You are free to decide whether or not to
                      accept a revised version of this Privacy Policy, but
                      accepting this Privacy Policy, as revised, is required for
                      you to continue accessing or using our Services. If you do
                      not agree to the terms of this Privacy Policy or any
                      revised version of this Privacy Policy, your sole recourse
                      is to terminate your access and use of our Services.
                      Except as otherwise expressly stated by us, your access
                      and use of our Services are subject to the version of this
                      Privacy Policy in effect at the time of access or use.
                    </li>
                  </ol>
                </div>

                <div className="mt-4">
                  <h5 className="text-blue">CONTACT INFORMATION</h5>
                  <div className="small-red-line mb-4"></div>
                  <ol style={{ margin: 0, padding: 0, listStyle: "none" }}>
                    <li className="font-sm">
                      If you have any questions about this Privacy Policy or the
                      way we collect information from you, or if you would like
                      to launch a complaint about anything related to this
                      Privacy Policy, you may contact us at the following email
                      address support@turbomoni.ng
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Index;
