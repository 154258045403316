import React from "react";
// import { Link} from "react-router-dom";
import "../../assets/css/style.css";
import logoBig from "../../assets/images/turbomoni-logo2.svg";
import googlePlayIcon from "../../assets/images/googlePlayIcon.svg";
import appleStoreIcon from "../../assets/images/appleStoreIcon.svg";
import facebookIcon from "../../assets/images/facebook.png";
import instagramIcon from "../../assets/images/instagram.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import twitterIcon from "../../assets/images/twitter.png";
import youtubeIcon from "../../assets/images/youtube.png";

const Index = () => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <footer>
      <div className="footer-bottom">
        <div className="container mt-80 py-5">
          <div className="row justify-content-between">
            <div className="col-lg-3 mt-3">
              <img src={logoBig} className="img-fluid" alt="logo" />

              <p className="text-white text-left mt-4">Download Apps:</p>
              <div className="d-flex justify-content-start mt-3 mb-4">
                <a
                  href="https://apps.apple.com/ng/app/investyield/id1616387280"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appleStoreIcon} className="img-fluid" alt="" />
                </a>
                <a
                  href="https://bit.ly/3Lgqs2Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlayIcon} className="img-fluid mr-2" alt="" />
                </a>
              </div>
              <div className="d-flex justify-content-start mt-3 mb-4">
                <a
                  href="https://www.facebook.com/investYieldofficial?mibextid=ZbWKwL"
                  className="socialIcon mr-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebookIcon} className="img-fluid" alt="" />
                </a>
                <a
                  href="https://instagram.com/investyield?igshid=Yzg5MTU1MDY="
                  className="socialIcon mr-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} className="img-fluid" alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/investyieldng/"
                  className="socialIcon mr-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} className="img-fluid" alt="" />
                </a>
                <a
                  href="https://youtube.com/@investyield8556"
                  className="socialIcon mr-2"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={youtubeIcon} className="img-fluid" alt="" />
                </a>
                <a
                  href="https://twitter.com/InvestYield?t=o9hW6SOwzmfIzCq3VM6BhA&s=09"
                  target="_blank"
                  rel="noreferrer"
                  className="socialIcon mr-2"
                >
                  <img src={twitterIcon} className="img-fluid" alt="" />
                </a>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col mt-4">
                  <h5 className="footer-bottom-header">Products</h5>
                  <div className="mt-3 d-flex flex-column">
                    <a href="/app" className="footer-bottom-links">
                      myPurse
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Stash
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Group Savings
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Target Savings
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Bills Payment
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Buy Now Pay Later
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Investments
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      Dollar Savings
                    </a>
                  </div>
                </div>
                <div className="col mt-4">
                  <h5 className="footer-bottom-header">Company</h5>
                  <div className="mt-3 d-flex flex-column">
                    <a href="/aboutus" className="mt-3 footer-bottom-links">
                      About us
                    </a>
                    <a href="/contactus" className="mt-3 footer-bottom-links">
                      Contact us
                    </a>
                    <a href="/faq" className="mt-3 footer-bottom-links">
                      FAQs
                    </a>
                    <a href="/#" className="mt-3 footer-bottom-links">
                      Blog
                    </a>
                  </div>
                </div>
                <div className="col mt-4">
                  <h5 className="footer-bottom-header">Legal</h5>
                  <div className="mt-3 d-flex flex-column">
                    <a href="/privacy" className="footer-bottom-links">
                      Privacy Policy
                    </a>
                    <a href="/app" className="mt-3 footer-bottom-links">
                      FAQs
                    </a>
                    <a href="/terms" className="mt-3 footer-bottom-links">
                      Terms and Conditions
                    </a>
                    <a href="/#" className="mt-3 footer-bottom-links">
                      Security
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 mt-4">
                  <h5 className="footer-bottom-header">Support</h5>
                  <div className="mt-3 d-flex flex-column">
                    <a
                      href="tel:+2348180248941"
                      className="footer-bottom-links"
                    >
                      +234 818 024 8941
                    </a>
                    <a
                      href="mailto:support@investyield.ng"
                      className="mt-3 footer-bottom-links"
                    >
                      support@turbomoni.ng
                    </a>
                    <a href="/" className="mt-3 footer-bottom-links">
                      No 1, Dr Adewale Oshin, Off Prince Bode Adewole, Off Fola
                      Oshibo, Lekki phase 1, Lagos State.
                    </a>
                  </div>
                  <h5 className="footer-bottom-header">
                    Subscribe to our newsletter
                  </h5>
                  <div className="mt-3 d-flex flex-column">
                    <form onSubmit={handleFormSubmit}>
                      <input
                        name="email"
                        type="text"
                        className="feedback-input"
                        placeholder="Email Address"
                      />
                      <input type="submit" value="Sign In" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-80">
            <div className="col-lg-12">
              <p className="copyright-text text-white">
                2023 turbomoni All Rights Reserved
              </p>
              <p className="font-x-sm mt-2 base-color text-white">
                turbomoni (formerly investYield) is a property of Revenuebull
                Technologies Limited, a company duly registered with the CAC
                with RC Number 1691483. turbomoni provides financial services
                under a cooperative license with registration number LSCS 17462.
                Both turbomoni and Revenuebull Technologies Limited are legal
                entities in Nigeria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Index;
