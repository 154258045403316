import React from 'react'

const LinkCard = () => {
    return (
        <div>
            <>LinkCard</>
        </div>
    )
}

export default LinkCard
