import React from "react";
import UnknowPerson from "../../../assets/images/null.png";
import {
  TeamText,
  TeamWrapper,
  TeamColumn,
  TeamName,
  Section,
} from "./TeamStyles";

const OurAdvisoryTeamComponent = () => {
  return (
    <Section smPadding="50px 10px" position="relative" id="Team">
      <TeamWrapper>
        {Array.from({ length: 5 }, (_, i) => {
          return (
            <TeamColumn key={i}>
              <img src={UnknowPerson} alt={""} />
              <div>
                <div></div>
                <TeamName>Adviser</TeamName>
                <TeamText>Null</TeamText>
              </div>
            </TeamColumn>
          );
        })}
      </TeamWrapper>
    </Section>
  );
};

export default OurAdvisoryTeamComponent;
