import React from "react";
import { TeamData } from "./data";
import {
  TeamText,
  TeamWrapper,
  TeamColumn,
  TeamName,
  Section,
} from "./TeamStyles";

const OurTeamComponent = () => {
  return (
    <Section smPadding="50px 10px" position="relative" id="Team">
      <TeamWrapper>
        {TeamData.map(({ img, id, name, title }) => (
          <TeamColumn key={id}>
            <img src={img} alt={""} />
            <div>
              <div></div>
              <TeamName>{name}</TeamName>
              <TeamText>{title}</TeamText>
            </div>
          </TeamColumn>
        ))}
      </TeamWrapper>
    </Section>
  );
};

export default OurTeamComponent;
