import React from "react";
import "../../assets/css/style.css";
// import "../../index.css";
const index = () => {
  return (
    <footer>
      <div className="container-fluid p-4">
        <div className="d-flex justify-content-center align-items-center">
          <div className="px-5 px-resp">
            <p className="loginpage-footer-p mb-0">
              2023 turbomoni All Rights Reserved
            </p>
          </div>
          <div className="px-5 px-resp">
            <a href="/terms" className="loginpage-footer-p">
              Terms and Conditions
            </a>
          </div>
          <div className="px-5 px-resp">
            <a href="/privacy" className="loginpage-footer-p">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
