import React from "react";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion/dist/framer-motion";
import BigImg from "../../assets/images/hero-1.png";
import BigImgTwo from "../../assets/images/hero-2.png";
import PhoneImg from "../../assets/images/about-bg.png";
import OurTeamComponent from "./Team";
import OurAdvisoryTeamComponent from "./Team/advisory";
import "./styles.css";
import { container, heroText, item } from "../../utils/motionConfig";
import { aboutData } from "../../utils";

const Index = () => {
  const [{ rowOne, rowTwo }] = aboutData;
  const { scrollYProgress } = useScroll();
  const parallaxScrolling = useTransform(
    scrollYProgress,
    [0, 1],
    ["0%", "100%"]
  );

  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        style={{ parallaxScrolling }}
      >
        <main>
          <section className="">
            <div className="container-fluid px-0">
              <div className="col-lg-12 px-5">
                <div className="d-flex justify-content-center">
                  <div className="col-lg-6 px-0 mt-60">
                    <div className="lh-lg">
                      <motion.h5
                        className="aboutUs-header text-center lh-lg"
                        variants={heroText}
                      >
                        Our vision is to provide an inclusive payment and
                        expense management ecosystem for Africa’s next one
                        billion people.
                      </motion.h5>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="col-lg-8 px-0 mt-5">
                    <div className="">
                      <motion.img
                        src={BigImg}
                        className="img-fluid"
                        alt="About Us"
                        initial={{ y: -200, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                      />
                    </div>
                    <div className="">
                      <motion.img
                        src={BigImgTwo}
                        className="img-fluid"
                        alt="About Us"
                        initial={{ x: 200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div className="container">
              <div className="col-lg-12 mt-60">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                  <div className="col-lg-6 px-0">
                    <div className="bg-grey about-pp-custom">
                      <h5 className="aboutUs-header">Who We Are</h5>
                      <div className="small-red-line"></div>
                      <p className="mt-5 whoWeAre-p">
                        At turbomoni, we believe that managing payments and
                        expenses should be easy and accessible to everyone, no
                        matter your financial situation. Our team is dedicated
                        to providing the best experience for our users. We are
                        constantly innovating and improving our platform to
                        ensure that it meets the changing needs of our
                        community.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-5 px-0 mt-5 pt-4">
                    <div>
                      <img src={PhoneImg}  className="img-fluid" alt="Phones" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Service Section */}
          <section className="container-fluid px-0" id="LearnMore">
            <div className="banner-img2 p-4 pb-5 d-flex align-items-center">
              <div className="container">
                <motion.div
                  className="col-lg-12 my-80"
                  variants={container}
                  initial="hidden"
                  exit="exit"
                  whileInView="show"
                  viewport={{ once: false }}
                >
                  <div className="row">
                    {rowOne?.map(({ id, title, desc }) => (
                      <motion.div className="col-lg-4" key={id} variants={item}>
                        <div className="d-flex flex-column align-items-center">
                          <h5 className="why-sub-headers">{title}</h5>
                          <div className="mt-4 col-lg-10 px-0">
                            <p className="why-p mb-5">{desc}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                  <div className="row">
                    {rowTwo?.map(({ id, title, desc }) => (
                      <motion.div className="col-lg-4" key={id} variants={item}>
                        <div className="d-flex flex-column align-items-center mt-5">
                          <h5 className="why-sub-headers">{title}</h5>
                          <div className="mt-4 col-lg-10 px-0">
                            <p className="why-p mb-0">{desc}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </section>

          <section className="">
            <div className="container">
              <div className="row mt-60">
                <div className="col-lg-12 pt-5">
                  <h5 className="aboutUs-header pb-1">Meet The Team</h5>
                  <div className="small-red-line"></div>
                  <p className="py-3">
                    The team is composed of seasoned professionals with diverse
                    skills in finance, investment management, banking, software
                    engineering and data sciences to ensure you get the best in
                    class experience.
                  </p>
                </div>
              </div>

              <OurTeamComponent />
            </div>
          </section>

          <section className="">
            <div className="container">
              <div className="row mt-60">
                <div className="col-lg-12">
                  <h5 className="aboutUs-header pb-1">
                    Advisory Team{" "}
                    <span className="text-dark fs-1">{"{Coming Soon}"}</span>
                  </h5>
                  <div className="small-red-line"></div>
                </div>
              </div>

              <OurAdvisoryTeamComponent />
            </div>
          </section>
        </main>
      </motion.div>
    </AnimatePresence>
  );
};

export default Index;
