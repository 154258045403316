import React from "react";
import "../../assets/css/style.css";
import landingImage from "../../assets/images/hero-img.svg";
import appleIcon from "../../assets/images/App Store.svg";
import googleIcon from "../../assets/images/Google Play.svg";
import ratingIcon from "../../assets/images/rating-icon.svg";
import dataSecurityIcon from "../../assets/images/data-security.svg";
import paymentOptionsIcon from "../../assets/images/payment-options.svg";
import { pageData, serviceData } from "../../utils";
import { SectionTextSlider, TestimonialCarousel } from "./owlCarousel";
import {
  heroText,
  fadeInUp,
  container,
  item,
  title,
  hoverEffect,
} from "../../utils/motionConfig";
import {
  AnimatePresence,
  motion,
  useScroll,
  useTransform,
} from "framer-motion/dist/framer-motion";

const LandingPage = () => {
  const [{ columnOne }] = pageData;
  const [{ rowOne, rowTwo }] = serviceData;
  const { scrollYProgress } = useScroll();
  const parallaxScrolling = useTransform(
    scrollYProgress,
    [0, 1],
    ["0%", "100%"]
  );

  return (
    <AnimatePresence>
      <motion.div
        initial="initial"
        animate="animate"
        style={{ parallaxScrolling }}
      >
        {/* Hero Section */}
        <div className="position-relative section-custom-height nav-edit">
          <div className="container">
            <div className="row justify-content-between px-4">
              <div className="col-lg-7 mt-5 d-flex justify-content-center flex-column">
                <motion.h1
                  className="text-white text-left landing-page-header mb-4"
                  variants={heroText}
                >
                  Be in control of your money, put it on <span>turbomoni.</span>
                </motion.h1>
                <div className="d-flex justify-content-start flex-column">
                  <motion.p
                    className="text-left banner-p mb-5"
                    variants={fadeInUp}
                  >
                    turbomoni was created for you, to put you back in control!
                    We recognise that sometimes it is difficult to track your
                    money; budgeting your expenses and making payments.
                  </motion.p>
                  <motion.p
                    className="text-white text-left banner-h5"
                    variants={fadeInUp}
                  >
                    Download Apps:
                  </motion.p>
                </div>

                <motion.div
                  className="d-flex justify-content-start flex-wrap mt-3"
                  variants={fadeInUp}
                >
                  <a
                    href="https://apps.apple.com/ng/app/investyield/id1616387280"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={appleIcon}
                      className="img-fluid mr-4 mb-1"
                      alt=""
                    />
                  </a>
                  <a
                    href="https://bit.ly/3Lgqs2Q"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googleIcon} className="img-fluid mb-1" alt="" />
                  </a>
                </motion.div>

                <motion.div
                  className="d-flex justify-content-start mt-4"
                  variants={fadeInUp}
                >
                  <p className="text-white text-left banner-h5">Rating :</p>
                  <img src={ratingIcon} className="img-fluid ml-4" alt="" />
                </motion.div>
              </div>
              <motion.div className="col-lg-5 mt-4">
                <motion.img
                  src={landingImage}
                  className="img-fluid"
                  alt="bg"
                  initial={{ x: 200, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                />
              </motion.div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-50">
            <motion.div
              className="col-lg-12"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <div className="d-flex flex-column align-items-center">
                <div className="header-btn mb-5">Why Us</div>
                <motion.h5 className="text-center welcomeText" variants={title}>
                  turbomoni is for everyone
                </motion.h5>
                <div className="col-lg-6 px-0">
                  <motion.p
                    className="text-center welcome-p"
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}
                  >
                    Whether you're a small business owner, freelancer, or just
                    someone that desires to purchase goods or services.
                  </motion.p>
                </div>
              </div>

              <div className="container mt-50 mb-5">
                <div className="row">
                  {columnOne?.map(({ id, desc, icon }) => (
                    <motion.div
                      className="col-lg-3 why-section"
                      key={id}
                      variants={item}
                    >
                      <div className="d-flex flex-column justify-content-center mobile">
                        <div className="d-flex justify-content-start align-items-start mt-3">
                          <motion.img
                            src={icon}
                            className="img-fluid"
                            alt=""
                            variants={hoverEffect}
                            whileHover="whileHover"
                            whileTap="whileTap"
                          />
                        </div>
                        <div className="mt-4 col-lg-10 px-0">
                          <p className="mb-0">{desc}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>

            <motion.div
              className="container mt-80 mb-5"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <div className="row d-flex justify-content-center">
                <div className="col-lg-5 mobile">
                  <motion.img
                    src={dataSecurityIcon}
                    className="img-fluid"
                    alt="Phones"
                    initial={{ y: -200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.8 }}
                  />
                </div>
                <motion.div
                  className="col-lg-5 d-flex justify-content-center flex-column "
                  variants={title}
                >
                  <h1 className="text-white text-left mt-4 mb-4 text-dark mobile">
                    Safety and Data <br /> Security
                  </h1>
                  <div className="d-flex justify-content-start flex-column mb-5 mobile">
                    <p className="text-left section-p  mb-4">
                      The products aggregated on turbomoni are provided and
                      delivered by regulated partner institutions, and managed
                      by professionals that will continue to ensure the
                      integrity of the platform is not compromised.
                      <br />
                      <br />
                      We use only the highest levels of Banking Security,
                      secured by 256 bits SSL security encryption, to ensure
                      that your information is completely protected at all
                      times. Also, we use state-of-the-art data encryption when
                      handling your financial information and two-factor
                      authentication (2FA).
                    </p>
                    <div className="w-50">
                      <a
                        href="#LearnMore"
                        className="btn-create-account px-3 py-2 fs-4"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>

            <motion.div
              className="container mt-150 mb-5"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <div className="row d-flex justify-content-center">
                <motion.div
                  className="col-lg-5 d-flex justify-content-center flex-column"
                  variants={title}
                >
                  <h1 className="text-white text-left mb-4 text-dark mobile">
                    Payment options <br /> and Simplicity
                  </h1>
                  <div className="d-flex justify-content-start flex-column mb-5 mobile">
                    <p className="text-left section-p  mb-4">
                      Turbomoni has partnered with Paystack and Flutterwave to
                      provide a range of secured payment options such as USSD,
                      Debit Cards or Bank transfers. You can also create your
                      own unique virtual bank account to receive payments
                      seamlessly.
                      <br />
                      <br />
                      Turbomoni was carefully designed by a team of seasoned
                      financial professionals, software engineers, data
                      scientists and behavioural economists, specially for you.
                    </p>
                    <div className="w-50">
                      <a
                        href="#LearnMore"
                        className="btn-create-account px-3 py-2 fs-4"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </motion.div>

                <div className="col-lg-5 mobile">
                  <motion.img
                    src={paymentOptionsIcon}
                    className="img-fluid"
                    alt=""
                    initial={{ y: -200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.8 }}
                  />
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Service Section */}
        <div className="container-fluid px-0" id="LearnMore">
          <div className="banner-img2 p-4 pb-5 d-flex align-items-center">
            <div className="container">
              <motion.div
                className="col-lg-12 my-80"
                variants={container}
                initial="hidden"
                exit="exit"
                whileInView="show"
                viewport={{ once: false }}
              >
                <div className="row">
                  {rowOne?.map(({ id, title, desc }) => (
                    <motion.div className="col-lg-4" key={id} variants={item}>
                      <div className="d-flex flex-column align-items-center">
                        <h5 className="why-sub-headers">{title}</h5>
                        <div className="mt-4 col-lg-10 px-0">
                          <p className="why-p mb-5">{desc}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
                <div className="row">
                  {rowTwo?.map(({ id, title, desc }) => (
                    <motion.div className="col-lg-4" key={id} variants={item}>
                      <div className="d-flex flex-column align-items-center mt-5">
                        <h5 className="why-sub-headers">{title}</h5>
                        <div className="mt-4 col-lg-10 px-0">
                          <p className="why-p mb-0">{desc}</p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="container px-0">
          <div className="row mt-150 mx-0 m-custom-sm">
            <motion.div
              className="col-lg-12"
              variants={container}
              initial="hidden"
              exit="exit"
              whileInView="show"
              viewport={{ once: false }}
            >
              <div className="d-flex flex-column align-items-center">
                <motion.h5 className="text-center welcomeText" variants={title}>
                  Money Management Made Easy
                </motion.h5>
                <div className="col-lg-8 px-0">
                  <motion.p
                    className="text-center welcome-p"
                    initial={{ y: 200, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, delay: 1 }}
                  >
                    Turbomoni provides you with special money management tools
                    that help you create mini-automated digital purses to budget
                    your expenses and payments. Now, all you need to do is
                    create a purse and turbomoni does the rest!
                  </motion.p>
                </div>
              </div>

              <div className="d-flex flex-column align-items-center testimonialBackgroundImage">
                <div className="centerDiv">
                  <motion.h5
                    className="text-center welcomeText mt-5 mb-5"
                    variants={title}
                  >
                    Hear From Our <br /> Happy Customers
                  </motion.h5>
                  <TestimonialCarousel />
                </div>
              </div>

              <div className="container mt-150" id="Products">
                <SectionTextSlider />
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default LandingPage;
